import React from "react";
import { graphql, Link } from "gatsby";
import * as components from "idyll-components";
import IdyllDocument from "idyll-document";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { css } from "@emotion/react";

// Custom Defined Components
// FIXME: Sidenotes will need to support children
// FIXME: Extract the span component out of these
const Side = ({ note, offset }) => {
  offset = offset || 0;
  return <>
    <span className="sidenote-number" />
    <span css={css`
      margin-top: ${offset}px;
    `} className="sidenote">{note}</span>
  </>;
};

// FIXME: Marginnotes will need to support children
// FIXME: Extract the span component out of these
const Margin = ({ note, offset }) => {
  return (
    <span
      css={css`
        margin-top: ${offset}px;
      `}
      className="marginnote">
      {note}
    </span>
  );
};

// Adding the components so that they're available in MDX &
// Idyll renderers
const shortcodes = { ...components, Margin, Side, Link };

// Template
const PostTemplateLayout = ({ data }) => {
  let frontmatter = data.mdx.frontmatter;
  let renderWith = frontmatter.renderWith || "mdx";

  let renderedResult;

  if (renderWith === "idyll") {
    renderedResult = (
      <div id="idyll-root">
        <IdyllDocument components={shortcodes} markup={data.mdx.rawBody} />
      </div>
    );
  } else {
    renderedResult = (
      <MDXProvider components={shortcodes}>
        <aside>
          <pre>Using file system route to render components with MDX</pre>
        </aside>
        <MDXRenderer>{data.mdx.body}</MDXRenderer>
      </MDXProvider>
    );
  }

  return (
    <div id="container">
      {/* <Header /> */}
      {renderedResult}
    </div>
  );
};

export default PostTemplateLayout;

// Query
export const query = graphql`
  query BlogPostById($id: String) {
    mdx(id: { eq: $id }) {
      body
      rawBody
      mdxAST
      frontmatter {
        renderWith
      }
    }
  }
`;
